import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { Main } from './Main';
import { SessionDataSource } from './model/SessionDataSource';

(function () {
    if (document.location.search.includes && document.location.search.includes('elementor-preview')) {
        console.log('elementor detected. not showing program.');
        return;
    }

    const nodeStartseite = document.getElementById('zww-outlet-startseite');
    const nodeProgrammseite = document.getElementById('zww-outlet-programmseite');
    const node = nodeStartseite ?? nodeProgrammseite;

    if (node === null) {
        console.warn('zww-outlet node not found');
        return;
    }

    ReactDOM.render(
        <React.StrictMode>
            <HashRouter>
                <Main short={nodeStartseite !== null} dataSource={classToDataSource(node.classList)} />
            </HashRouter>
        </React.StrictMode>,
        node
    );
})();

function classToDataSource(classList: DOMTokenList): SessionDataSource {
    if (classList.contains('zww-year-2023')) {
        return SessionDataSource.year2023
    }

    if (classList.contains('zww-year-2024')) {
        return SessionDataSource.year2024
    }

    return SessionDataSource.eventTool;
}
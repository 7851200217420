import { useMap, useMapEvent } from 'react-leaflet';

export interface MapZoomMoveListenerProps {
    setMapLocation: (center: { lat: number; lng: number }, mapZoom: number) => void;
}

const MapZoomMoveListener = ({ setMapLocation }: MapZoomMoveListenerProps) => {
    const map = useMap();

    useMapEvent('zoomend', () => {
        setMapLocation(map.getCenter(), map.getZoom());
    });

    useMapEvent('moveend', () => {
        setMapLocation(map.getCenter(), map.getZoom());
    });

    return null;
};

export default MapZoomMoveListener;

import { useState } from 'react';
import { AppState } from '../model/AppState';

export const useAppState = () => {
    const [state, setState] = useState<AppState>({
        filtersVisible: false,
        selectedFormats: [],
        selectedTopics: [],
        selectedTargetGroups: [],
        selectedLocationTypes: [],

        mapVisible: false,
        mapCenter: { lng: 9.9602, lat: 49.88 },
        mapZoom: 10,
    });

    return {
        ...state,
        setFiltersVisible: (filtersVisible: boolean) => {
            setState({ ...state, filtersVisible });
        },
        setSelectedFormats: (selectedFormats: string[]) => {
            setState({ ...state, selectedFormats });
        },
        setSelectedTopics: (selectedTopics: string[]) => {
            setState({ ...state, selectedTopics });
        },
        setSelectedTargetGroups: (selectedTargetGroups: number[]) => {
            setState({ ...state, selectedTargetGroups });
        },
        setSelectedLocationTypes: (selectedLocationTypes: number[]) => {
            setState({ ...state, selectedLocationTypes });
        },

        setMapVisible: (mapVisible: boolean) => {
            setState({ ...state, mapVisible });
        },
        setMapLocation: (mapCenter: { lat: number; lng: number }, mapZoom: number) => {
            if (
                state.mapCenter.lat === mapCenter.lat &&
                state.mapCenter.lng === mapCenter.lng &&
                state.mapZoom === mapZoom
            ) {
                return;
            }

            setState({ ...state, mapCenter, mapZoom });
        },
    };
};

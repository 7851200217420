import { useMemo } from 'react';
import { useSessionContext } from '../SessionContext';
import './session-filter.scss';

interface Props {
    filtersVisible: boolean;
    mapVisible: boolean;
    selectedFormats: string[];
    selectedTopics: string[];
    selectedTargetGroups: number[];
    selectedLocationTypes: number[];
    setFiltersVisible: (filtersVisible: boolean) => void;
    setMapVisible: (mapVisible: boolean) => void;
    setSelectedFormats: (selectedFormats: string[]) => void;
    setSelectedTopics: (selectedTopics: string[]) => void;
    setSelectedTargetGroups: (selectedTargetGroups: number[]) => void;
    setSelectedLocationTypes: (selectedLocationTypes: number[]) => void;
}

export const SessionFilter = (props: Props) => {
    const { categories, sessions } = useSessionContext();

    const eventTopicsInUse = useMemo(() => {
        return categories.eventTopic.filter((topic) => sessions.some((session) => session.eventTopic === topic));
    }, [categories, sessions]);

    const eventFormatsInUse = useMemo(() => {
        return categories.eventFormat.filter((format) =>
            sessions.some((session) => session.eventFormat === format || session.eventFormatSecondary === format)
        );
    }, [categories, sessions]);

    return (
        <div className="zww-session-filter">
            <div className="zww-session-filter-button-holder">
                <button
                    className="zww-session-filter-open"
                    onClick={() => props.setFiltersVisible(!props.filtersVisible)}
                >
                    {props.filtersVisible ? (
                        <>
                            <i className="fas fa-chevron-down"></i> Filter ausblenden
                        </>
                    ) : (
                        <>
                            <i className="fas fa-chevron-right"></i> Filter anzeigen
                        </>
                    )}
                </button>

                <button className="zww-session-filter-open" onClick={() => props.setMapVisible(!props.mapVisible)}>
                    {props.mapVisible ? (
                        <>
                            <i className="fas fa-chevron-down"></i> Karte ausblenden
                        </>
                    ) : (
                        <>
                            <i className="fas fa-chevron-right"></i> Karte anzeigen
                        </>
                    )}
                </button>
            </div>

            {props.filtersVisible && (
                <div className="zww-flex">
                    <div>
                        <h4>Themenbereich</h4>
                        <ul className="zww-filter-options">
                            {eventTopicsInUse.map((value, i) => {
                                const isSelected = props.selectedTopics.includes(value);
                                return (
                                    <li
                                        key={i}
                                        className={isSelected ? 'zww-selected' : undefined}
                                        onClick={() => {
                                            if (isSelected) {
                                                props.setSelectedTopics(
                                                    props.selectedTopics.filter((x) => x !== value)
                                                );
                                            } else {
                                                props.setSelectedTopics([...props.selectedTopics, value]);
                                            }
                                        }}
                                    >
                                        {value} {isSelected ? <i className="fas fa-check"></i> : null}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div>
                        <h4>Art des Events</h4>
                        <ul className="zww-filter-options">
                            {eventFormatsInUse.map((value, i) => {
                                const isSelected = props.selectedFormats.includes(value);
                                return (
                                    <li
                                        key={i}
                                        className={isSelected ? 'zww-selected' : undefined}
                                        onClick={() => {
                                            if (isSelected) {
                                                props.setSelectedFormats(
                                                    props.selectedFormats.filter((x) => x !== value)
                                                );
                                            } else {
                                                props.setSelectedFormats([...props.selectedFormats, value]);
                                            }
                                        }}
                                    >
                                        {value} {isSelected ? <i className="fas fa-check"></i> : null}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>

                    {categories.targetGroup === undefined ? null : (
                        <div>
                            <h4>Zielgruppe</h4>
                            <ul className="zww-filter-options">
                                {Object.entries(categories.targetGroup).map(([id, text]) => {
                                    const numericId = Number.parseInt(id, 10);
                                    const isSelected = props.selectedTargetGroups.includes(numericId);

                                    return (
                                        <li
                                            key={numericId}
                                            className={isSelected ? 'zww-selected' : undefined}
                                            onClick={() => {
                                                if (isSelected) {
                                                    props.setSelectedTargetGroups(
                                                        props.selectedTargetGroups.filter((x) => x !== numericId)
                                                    );
                                                } else {
                                                    props.setSelectedTargetGroups([
                                                        ...props.selectedTargetGroups,
                                                        numericId,
                                                    ]);
                                                }
                                            }}
                                        >
                                            {text} {isSelected ? <i className="fas fa-check"></i> : null}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}

                    {categories.locationType === undefined ? null : (
                        <div>
                            <h4>Format</h4>
                            <ul className="zww-filter-options">
                                {Object.entries(categories.locationType).map(([id, text]) => {
                                    const numericId = Number.parseInt(id, 10);
                                    const isSelected = props.selectedLocationTypes.includes(numericId);

                                    return (
                                        <li
                                            key={numericId}
                                            className={isSelected ? 'zww-selected' : undefined}
                                            onClick={() => {
                                                if (isSelected) {
                                                    props.setSelectedLocationTypes(
                                                        props.selectedLocationTypes.filter((x) => x !== numericId)
                                                    );
                                                } else {
                                                    props.setSelectedLocationTypes([
                                                        ...props.selectedLocationTypes,
                                                        numericId,
                                                    ]);
                                                }
                                            }}
                                        >
                                            {text} {isSelected ? <i className="fas fa-check"></i> : null}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

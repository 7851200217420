import { useMemo } from 'react';
import { Session } from '../../model/Session';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import SessionPopup from './SessionPopup';
import MapZoomMoveListener from './MapZoomMoveListener';

const snapThres = 0.00025;

interface SessionGroup {
    sessions: Session[];
    latSum: number;
    lngSum: number;
}

export interface SessionMapProps {
    sessions: Session[];

    mapCenter: { lat: number; lng: number };
    mapZoom: number;
    setMapLocation: (center: { lat: number; lng: number }, mapZoom: number) => void;
}

export const SessionMap = ({ sessions, mapCenter, mapZoom, setMapLocation }: SessionMapProps) => {
    const groups = useMemo(() => {
        return sessions
            ?.filter((session) => !session.cancelled)
            .reduce((groups: SessionGroup[], session: Session): SessionGroup[] => {
                let { lat, lng } = session.location ?? {};
                if (!lat || !lng) return groups;

                lat %= 180;
                lng %= 180;

                const matchingGroup = groups.find((group) => {
                    if (lat === undefined || lng === undefined) {
                        throw new Error('unreachable, lat/lng cannot be undefined here');
                    }

                    return (
                        Math.abs(group.latSum / group.sessions.length - lat) < snapThres &&
                        Math.abs(group.lngSum / group.sessions.length - lng) < snapThres
                    );
                });

                if (matchingGroup) {
                    matchingGroup.sessions.push(session);
                    matchingGroup.latSum += lat;
                    matchingGroup.lngSum += lng;
                } else {
                    groups.push({
                        sessions: [session],
                        latSum: lat,
                        lngSum: lng,
                    });
                }

                return groups;
            }, []);
    }, [sessions]);

    return (
        <MapContainer center={mapCenter} zoom={mapZoom} style={{ width: '100%', height: '80vh' }}>
            <MapZoomMoveListener setMapLocation={setMapLocation} />
            <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {groups.map((group) => (
                <Marker
                    key={group.sessions[0].id}
                    position={{
                        lng: group.lngSum / group.sessions.length,
                        lat: group.latSum / group.sessions.length,
                    }}
                >
                    <SessionPopup sessions={group.sessions} />
                </Marker>
            ))}
        </MapContainer>
    );
};

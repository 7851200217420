import './session-list.scss';

import { Session } from '../model/Session';
import { formatDate, formatWeekday } from '../util/dateUtil';
import { SessionList } from './SessionList';
import { Fragment } from 'react';
import { DaySelector } from './DaySelector';
import { SessionFilter } from './SessionFilter';
import { useParams } from 'react-router-dom';
import { useSessions } from './SessionContext';
import { SessionMap } from './SessionMap/SessionMap';

interface Props {
    short?: boolean;
    filtersVisible: boolean;
    selectedFormats: string[];
    selectedTopics: string[];
    selectedTargetGroups: number[];
    selectedLocationTypes: number[];
    setFiltersVisible: (filtersVisible: boolean) => void;
    setSelectedFormats: (selectedFormats: string[]) => void;
    setSelectedTopics: (selectedTopics: string[]) => void;
    setSelectedTargetGroups: (selectedTargetGroups: number[]) => void;
    setSelectedLocationTypes: (selectedLocationTypes: number[]) => void;

    mapVisible: boolean;
    setMapVisible: (mapVisible: boolean) => void;
    mapCenter: { lat: number; lng: number };
    mapZoom: number;
    setMapLocation: (center: { lat: number; lng: number }, mapZoom: number) => void;
}

export const SessionTable = ({ short, mapCenter, mapZoom, setMapLocation, ...filterProps }: Props) => {
    const sessions = useSessions();
    const filteredSessions = sessions.filter(
        (session) =>
            (filterProps.selectedFormats.length === 0 ||
                filterProps.selectedFormats.includes(session.eventFormat ?? '') ||
                filterProps.selectedFormats.includes(session.eventFormatSecondary ?? '')) &&
            (filterProps.selectedTopics.length === 0 ||
                filterProps.selectedTopics.includes(session.eventTopic ?? '')) &&
            (filterProps.selectedTargetGroups.length === 0 ||
                filterProps.selectedTargetGroups.some((targetGroup) =>
                    (session.targetGroups ?? []).includes(targetGroup)
                )) &&
            (filterProps.selectedLocationTypes.length === 0 ||
                (session.locationType !== undefined &&
                    filterProps.selectedLocationTypes.includes(session.locationType)))
    );

    const partitionedSessions = Session.partitionByDate(filteredSessions);
    const dayList = Object.keys(partitionedSessions).sort();
    let selectedDay = useParams().date;

    if (selectedDay !== undefined && !dayList.includes(selectedDay)) {
        selectedDay = undefined;
    }

    const filterBlock = short ? null : (
        <>
            <SessionFilter {...filterProps} />
            <DaySelector dayList={dayList} selectedDay={selectedDay} />
        </>
    );

    if (dayList.length === 0) {
        return (
            <>
                {filterBlock}
                <p>Es wurden keine Events gefunden, die den gewählten Filterkriterien entsprechen.</p>
            </>
        );
    }

    return (
        <>
            {filterBlock}
            {filterProps.mapVisible && (
                <SessionMap
                    sessions={selectedDay === undefined ? filteredSessions : partitionedSessions[selectedDay]}
                    mapCenter={mapCenter}
                    mapZoom={mapZoom}
                    setMapLocation={setMapLocation}
                />
            )}
            <div>
                {(selectedDay ? [selectedDay] : dayList).map((key) => (
                    <Fragment key={key}>
                        <div className="zww-day-row">
                            <span>
                                {formatWeekday(key)} | {formatDate(key)}
                            </span>
                        </div>
                        <SessionList
                            shortListingHref={short ? `/programm-2025/#/sessions/by-date/${key}` : undefined}
                            sessions={partitionedSessions[key]}
                        />
                    </Fragment>
                ))}
            </div>
        </>
    );
};

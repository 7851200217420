import { useEffect, useState } from 'react';
import { SessionFeed, SessionFeedStatus } from '../model/SessionFeed';
import { mapValues, keyBy } from 'lodash';
import { SessionDataSource } from '../model/SessionDataSource';

const dataUrls = {
    [SessionDataSource.eventTool]: 'https://event-tool.zukunftswoche-mainfranken.de/export/session.json',
    [SessionDataSource.year2023]: 'https://zukunftswoche-mainfranken.de/sessions-2023.json',
    [SessionDataSource.year2024]: 'https://zukunftswoche-mainfranken.de/sessions-2024.json',
}

export const useSessionFeed = (dataSource: SessionDataSource) => {
    const [state, setState] = useState<SessionFeed>({ status: SessionFeedStatus.FetchingSessionData });

    useEffect(() => {
        fetch(dataUrls[dataSource]).then(async (res) => {
            const data = await res.json();

            if (data.categories.locationType) {
                data.categories.locationType = mapValues(
                    keyBy(data.categories.locationType, 'id'),
                    ({ name }: { name: string }) => name
                );
            }

            if (data.categories.targetGroup) {
                data.categories.targetGroup = mapValues(
                    keyBy(data.categories.targetGroup, 'id'),
                    ({ name }: { name: string }) => name
                );
            }

            setState((oldState) => ({ ...oldState, status: SessionFeedStatus.InitializationComplete, data }));
        });
    }, [dataSource]);

    return state;
};

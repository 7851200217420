import { Popup } from 'react-leaflet';
import { Fragment } from 'react';
import { Session } from '../../model/Session';
import { formatDateTime } from '../../util/dateUtil';

import './session-popup.scss';
import { Link } from 'react-router-dom';

export interface SessionPopupProps {
    sessions: Session[];
}

const SessionPopup = ({ sessions }: SessionPopupProps) => (
    <Popup>
        <p className="session location name">{sessions[0].location?.name}</p>
        <p className="session location address">
            {sessions[0].location?.streetNo}
            {sessions[0].location?.city !== 'Würzburg' && (
                <>
                    , {sessions[0].location?.zipcode} {sessions[0].location?.city}
                </>
            )}
        </p>
        <hr />
        {[...sessions]
            .sort((a, b) => a.start.localeCompare(b.start))
            .map((session, index) => (
                <Fragment key={index}>
                    <h3 className="session title">
                        <Link to={`/sessions/${session.id}`}>{session.title}</Link>
                    </h3>
                    <p className="session host">
                        {session.host.name} @ {formatDateTime(session.start)}
                    </p>
                </Fragment>
            ))}
    </Popup>
);

export default SessionPopup;

export function formatWeekday(d: string): string {
    return new Date(d).toLocaleDateString('de-de', {
        weekday: 'long',
        year: undefined,
        month: undefined,
        day: undefined,
    });
}

export function formatDate(d: string): string {
    return new Date(d).toLocaleDateString('de-de', {
        weekday: undefined,
        year: undefined,
        month: 'long',
        day: 'numeric',
    });
}

export function formatWeekdayDate(d: string): string {
    return new Date(d).toLocaleDateString('de-de', { weekday: 'long', year: undefined, month: 'long', day: 'numeric' });
}

export function formatTime(dt: string): string {
    return new Date(dt).toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
}

export function formatDateTime(dt: string): string {
    return new Date(dt).toLocaleDateString('de-de', {
        weekday: undefined,
        year: undefined,
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });
}
